import * as React from "react"
import { graphql, PageProps } from "gatsby"
import Seo from "../templates/seo"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
import Post from "../components/organisms/post"
import Sidebar from "../components/organisms/sidebar"
import { l_page_container, l_page_content } from "../components/atoms/__layout"

const BlogPost: React.FC<PageProps<Queries.BlogPostBySlugQuery>> = ({
  data,
}) => {
  const post = data.markdownRemark
  const siteTitle = data.site?.siteMetadata?.title
  const name = data.site?.siteMetadata?.author?.name
  const description = data?.site?.siteMetadata?.description
  const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title={post?.frontmatter?.title} description={post?.frontmatter?.description} />

      <div css={l_page_container}>
        <section css={l_page_content}>
          <Post post={post} />
        </section>
        {breakpoints.pc ? <Sidebar toc={true} tableOfContents={post?.tableOfContents} author={name} description={description} /> : <div />}
      </div>



      {/* 
      <div css={l_page_container}>
        <div css={l_page_width}>
          <Post post={post} />
        </div>
        {breakpoints.pc ? (
          <Sidebar
            toc={true}
            tableOfContents={post?.tableOfContents}
            author={name}
            description={description}
          />
        ) : (
          <div />
        )}
      </div> */}
    </Layout>
  )
}

export default BlogPost

export const Pagequery = graphql`
  query BlogPostBySlug($id: String) {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents(absolute: false)
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
        tags
      }
    }
  }
`